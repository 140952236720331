@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=MedievalSharp&family=Noto+Sans+Runic&display=swap');

body {
  margin: 0;
  margin-top:0px;
  padding: 5x;
  background-color: #000000;
  color: rgb(255, 255, 255);
  font-family: "IBM Plex Mono", monospace;
  font-size: 20px;
  letter-spacing: -0.25px;

}

main {
  background-color: rgb(255, 255, 255);
  width: 100%;
  min-height:100vh;
  color:black;
}

main .price-container {
  position: absolute;
  margin: 0 auto;
  width: 100%;
  top:25%;
  background-color: #ffffff;
}

.spacer{
  height: 25px;
}

.App {
  text-align: center;
}


.marquee {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  position:absolute;
  z-index:100;
  top:0px;
  height:25px;
  font-size:25px;
  font-weight: 500;
  font-family: "MedievalSharp", cursive;
  background:rgba(84, 84, 84, 0.2);
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px); /* For Safari */
  width:100%;
  left:0px;
  text-align: left;
}

.marquee span {
  display: inline-block;
  animation: marquee 200s linear infinite, colorRotate 10s linear infinite;
}

@keyframes marquee {
  0% {
    transform: translateX(20vw);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes colorRotate {
  0% {
    color: rgb(0, 251, 71);
  }
  25% {
    color: rgb(251, 0, 71);
  }
  50% {
    color: rgb(71, 0, 251);
  }
  75% {
    color: rgb(251, 251, 0);
  }
  100% {
    color: rgb(0, 251, 71);
  }
}

button{
  animation: colorRotate 10s linear infinite;
  background:rgba(84, 84, 84, 0.2);
  backdrop-filter: blur(100px);
  -webkit-backdrop-filter: blur(100px); /* For Safari */
  padding:5px 10px;
  margin-right:5px;
  border:none;
}


h1{
  font-family: "MedievalSharp", cursive;
  font-size: 4vw; /* Make the font size responsive */
  background: #0000008a;
  padding:30px 50px;
  line-height: 45px;
  border-radius:100px;
  backdrop-filter: blur(50px);
  -webkit-backdrop-filter: blur(50px); /* For Safari */
  /* border: 1px solid rgba(255, 255, 255, 0.069); */
}

h2{
  font-family: "MedievalSharp", cursive;
}

h4{
  font-family: "MedievalSharp", cursive;
  font-size:16px;
}

h6{
  font-family: "MedievalSharp", cursive;
  font-size:12px;
}

h6 a{
  color: #FFA500;
}

p{
  font-size:30px;
  font-weight: 400;;
  font-family: "IBM Plex Mono", monospace;

}

.live{
  margin:-20px 0px 10px 0px;
}

select, input {
  font-size: 20px;
  width: auto;
  font-family: "IBM Plex Mono", monospace;
}

.footer{
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

.App-header {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.background-scene {
  position: fixed; /* Position the scene behind all content */
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure it stays behind other content */
}

.app-content {
  position: relative; /* Ensures content is positioned correctly */
  z-index: 1; /* Ensures content is above the background scene */
  /* Your styling for app content here */
}

/* Existing styles above */

/* Mobile theme starts here */
@media (max-width: 768px) {
  body {
      margin-top: 0; /* Adjust the top margin for mobile */
  }

  .spacer{
    height: 20px;
  }

  .background-scene {

  }

  .App-header {
      min-height: 20vh; /* Increase the header size on mobile for better visibility */
  }

  .app-content {
      /* Adjust padding or margins as needed for mobile layout */
  }

  h1 {
      font-size: 9.5vw; /* Make the font size responsive */
      font-family: "MedievalSharp", cursive;
      padding:10px 40px;
      line-height: 45px;
      border-radius:0px;
      backdrop-filter: blur(0px);
      -webkit-backdrop-filter: blur(0px); /* For Safari */
      background-color: #00000000;
    }

  h2 {
      font-size: 5vw; /* Make the font size responsive */
  }

  h4{
    font-size:9px;
  }

  .coffee{
    margin:0px auto;
    width:90%;
    padding: 0px;
  }

  p{
    font-size:20px;
  }

  .footer {
      position: relative; /* Adjust footer position for mobile */
      padding: 20px 0; /* Add some padding */
  }

  /* Adjust layout for the chart container to fit within smaller screens */
  .chart-container {
      width: 90%; /* Reduce width to fit on screen */
  }

  /* Make images and other elements responsive */
  img {
      max-width: 100%;
      height: auto;
  }

  /* Adjust select and input fields for better usability on mobile */
  select, input {
      font-size: 20px; /* Increase font size for easier interaction */
      width: auto; /* Adjust width to fit content or screen */
  }
}

